export default async function copyTextToClipboard(text:string, 
    content: string, 
    title?: string) {
    if( 'dbd_aos' in window ) {
        (window as any).dbd_aos.copyToClipboard(content+"\n\n"+text, true);
    } else {
        if( window.navigator  && window.location.href.indexOf('https') !== -1) {
            navigator.share({
                title: title ? title.substring(0, 12) : '쑥채널',
                text: content.substring(0, 100),
                url: text
              });
        }  else {
            if ('clipboard' in navigator) {
                return await navigator.clipboard.writeText(text);
            } else {
                return document.execCommand('copy', true, text);
            }
        }
    }
  } 

export const copyTextToClipboardWithoutUrl = async (text: string) => {
    if( 'dbd_aos' in window ) {
        (window as any).dbd_aos.copyToClipboard(text, true);
    } else {
        // if( window.navigator  && window.location.href.indexOf('https') !== -1) {
        //     navigator.share({
        //         text,
        //         url: ''
        //       });
        // }  else {
        // }
        if ('clipboard' in navigator) {
            return await navigator.clipboard.writeText(text);
        } else {
            return document.execCommand('copy', true, text);
        }
    }
}