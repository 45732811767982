/** ------- 이름 마스킹 함수 -------------
 * 이름에 역할 글자가 들어가면 보여주기
 * 2글자인 경우 앞글자만 보여주기
 * 3글자 이상인 경우 앞/뒤 글자 빼고 다 마스킹
 * @param name (string) 마스킹 처리할 텍스트
 * @returns 마스킹 처리된 텍스트
 */
const maskingName = (name: string) => {
  const roleNames = ['엄마', '아빠', '할머니', '할아버지', '함미', '하삐', '이모', '고모', '가족', '맘']
  
  if(!name){
    return
  }

  if (name?.length <= 2) {
    return name.slice(0, 1) + Array.from({ length: name.length - 1 }, () => '*').join('')
  } else {
    return name.slice(0, 1) + Array.from({ length: name.length - 2 }, () => '*').join('') + name.slice(name.length - 1, name.length)
  }
}

export default maskingName;
