interface HorizontalCardProps{
    children?:React.ReactNode
    boundaries?:boolean
    classStyle?:any
}

const HorizontalLeftContents = ({children, boundaries, classStyle}:HorizontalCardProps) =>{
    return (
        <div className={`flex-none ${classStyle ?? ""} ${boundaries ? 'border-r-[1px] border-[#E4E4E4]' : ''}`}>
            {children}
        </div>
    )
}

const HorizontalRightContents = ({children, classStyle}:HorizontalCardProps) =>{
    return (
        <div className={`flex-1 ${classStyle ?? ""}`}>
            {children}
        </div>
    )
}

const HorizontalCardWrapper = ({children, boundaries, classStyle} : HorizontalCardProps) =>{
    return (
        <div className={`w-[100%] my-2 mx-auto flex space-x-3 bg-white rounded-md ${classStyle}`}>
            {children}
        </div>
    )
}

export { HorizontalLeftContents, HorizontalRightContents, HorizontalCardWrapper}