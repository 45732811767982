import { atom } from 'recoil';
// import { recoilPersist } from 'recoil-persist'
// const { persistAtom } = recoilPersist()

export enum IReviewSort {
  score = 'score',
  desc = 'createdAt' 
}

export const reviewSortbyState = atom<IReviewSort>({
    key: 'reviewSortby',
    default: IReviewSort.score,
    // effects_UNSTABLE: [persistAtom]
});