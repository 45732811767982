import { ButtonBase } from "@mui/material"
import { AnimatePresence, motion } from "framer-motion"
import { CSSProperties } from "react"


interface PositionPopupProps {
    isOpen : boolean, 
    position: "bottom" | "middle",
    onClose : () => void,
    children : React.ReactNode
    style?: CSSProperties
}

const PositionPopup = ({ 
    isOpen, 
    position,
    onClose,
    children,
    style
} : PositionPopupProps) => {

    const returnStyle = (position:string) => {
        switch(position){
            case "bottom":
                return "fixed bottom-0 w-full z-30 bg-white rounded-t-xl py-4"
            case "middle":
                return "fixed top-1/3 translate-y-[-50%] left-[7%] w-[86%] z-30 bg-white rounded-2xl px-4 pt-2"
        }
    }

    return (
        <AnimatePresence>
            <div key={`AnimatePresence-child`} onClick={onClose} className={`${style ?? ""} ${isOpen ? "fixed top-0 z-30 backdrop-brightness-50 h-screen w-full" : ""}`}/>
            <motion.div
                className={`max-h-[530px] overflow-y-auto ${returnStyle(position)}`}
                variants={{
                    isOpen : { y : 0, display : "block"},
                    isClose : { y : "6%", display : "none"}
                }}
                animate={isOpen ? "isOpen" : "isClose"}
            >
                {children}

                <ButtonBase onClick={onClose}></ButtonBase>
            </motion.div>
        </AnimatePresence>
    )
}

export default PositionPopup