import { IProductDetailInfos } from "interface/DetailInterface"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {faChevronUp, faChevronDown} from '@fortawesome/pro-regular-svg-icons'
import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { priceComma } from "util/priceComma"
import { IOpenTab } from "interface/ProductsInterface"

interface IProductBaseInfoProps{
    openTab:IOpenTab,
    setOpenTab: Dispatch<SetStateAction<IOpenTab>>,
    productDetail ?: IProductDetailInfos | null,
}

const ProductBaseInfo = ({ productDetail, openTab, setOpenTab } : IProductBaseInfoProps) =>{

    const [baseInfo, setBaseInfo] = useState([{title : '', contents : ''}])

    {/** 상품 기본정보 : 파싱 -> setState */}
    useEffect(()=>{ 
        if(productDetail){
            const dutyinfo = JSON.parse(productDetail.baseInfo.dutyInfo)
            const dutyInfoContent = dutyinfo.contents
    
            let baseInfoObjectification:any = [];
    
            dutyInfoContent.forEach((element : any) => {
                let value = Object.values(element)[0]
                let key = Object.keys(element)[0]
    
                baseInfoObjectification.push({ title : key, contents : value })
            });
    
            setBaseInfo(baseInfoObjectification)
        }

    },[productDetail])

    return (<div className={`bg-white border-t-[4px] border-[#F4F4F4]`}>

        {productDetail && (<>

            {/** 상품정보 제공고시 */}
            <div className="p-4 relative border-b-[1px]">
                <span className="text-[16px] font-bold">상품정보 제공공시</span>
                <FontAwesomeIcon className="absolute top-[50%] translate-y-[-50%] right-4 w-4 h-4" 
                    icon={openTab.product ? faChevronUp : faChevronDown}
                    onClick={()=>{setOpenTab(prev=>{
                        return {
                            ...prev, product : !prev.product
                        }
                    })}}/>
            </div>

            {openTab.product && baseInfo && (
                <table className="py-6 text-[12px] border-y-[1px] w-full">
                    <tbody>
                        {baseInfo.map((item, idx)=>{
                            return(
                                <tr className="border-b-[1px]" key={idx + item.title}>
                                    <td className=" bg-[#F9F9F9] text-[#858585] p-4 py-2 w-[160px]">{item.title}</td>
                                    <td className="pl-4">{item.contents}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            )}        

            {/** 배송 정보 */}
            <div className="p-4 relative border-b-[1px]">
                <span className="text-[16px] font-bold">배송 정보</span>
                <FontAwesomeIcon className="absolute top-[50%] translate-y-[-50%] right-4 w-4 h-4" 
                    icon={openTab.delivery ? faChevronUp : faChevronDown}
                    onClick={()=>{setOpenTab(prev=>{
                        return {
                            ...prev, delivery : !prev.delivery
                        }
                    })}}/>
            </div>

            {openTab.delivery && (
                <table className="py-6 text-[12px] border-y-[1px] w-full">
                    <tbody>
                        <tr className="border-b-[1px]">
                            <td className=" bg-[#F9F9F9] text-[#858585] pl-4 py-2 w-[160px]">배송사</td>
                            <td className="pl-4">{productDetail.deliveryFee.deliveryCompanyTypeLabel ? productDetail.deliveryFee.deliveryCompanyTypeLabel : "미정"}</td>
                        </tr>
                        <tr className="border-b-[1px]">
                            <td className=" bg-[#F9F9F9] text-[#858585] pl-4 py-2">배송비</td>
                            <td className="pl-4">{productDetail.deliveryFee.deliveryAmt === 0 ? '무료배송' : priceComma(productDetail.deliveryFee.deliveryAmt) + '원'}</td>
                        </tr>
                    </tbody>
                </table>
            )}

            {/** 교환/반품 안내  */}
            <div className="p-4 relative border-b-[1px]">
                <span className="text-[16px] font-bold">교환/반품 안내</span>
                <FontAwesomeIcon className="absolute top-[50%] translate-y-[-50%] right-4 w-4 h-4" 
                    icon={openTab.refund ? faChevronUp : faChevronDown}
                    onClick={()=>{setOpenTab(prev=>{
                        return {
                            ...prev, refund : !prev.refund
                        }
                    })}}/>
            </div>

            {openTab.refund && (
                <table className="py-6 text-[12px] border-y-[1px] w-full">
                    <tbody>
                        <tr className="border-b-[1px]">
                            <td className=" bg-[#F9F9F9] text-[#858585] pl-4 py-2 w-[160px]">교환 / 반품 주소</td>
                            <td className="pl-4">{productDetail.deliveryFee?.returnWarehouse?.address}</td>
                        </tr>
                        <tr>
                            <td className=" bg-[#F9F9F9] text-[#858585] pl-4 py-2">교환 / 반품 배송비(편도)</td>
                            <td className="pl-4">{priceComma(productDetail.deliveryFee.returnDeliveryAmt)}원</td>
                        </tr>
                        <tr className="text-14 text-red-500">
                            <td colSpan={2} className="pl-4">교환/반품 배송비는 왕복으로 부과됩니다. 상품마다 상이할수 있습니다.</td>
                        </tr>
                    </tbody>
                </table>
            )}
       </> )} 

    </div>)
}

export default ProductBaseInfo