import { useRecoilState } from "recoil";
import { shopAccountTokenState } from "states/shopAccountTokenState";

interface ProfileResponse {
  result: boolean;
  userKey: string;
}

export default function useShopUser() {
    const [accountToken, setAccountToken] = useRecoilState(shopAccountTokenState);
    return { userkey: accountToken, isLoading: false }
}