import { IProductDetailInfos } from "interface/DetailInterface";
import { useEffect, useState } from "react";
import ProductInqury from "./ProductInqury";

interface IBottomContentsProps {
    productDetail : IProductDetailInfos | undefined | null
}

const BottomContents = ({productDetail} : IBottomContentsProps) =>{
    const [openInquiry, setOpenInquiry] = useState<boolean>(false)

    return(<fieldset className="pb-20 bg-white mt-2">
    
        <div className="py-6 px-4 h-[168px] w-full">
            {/** 쇼핑문의 제목 */}
            <div className="text-[17px] font-semibold mb-2">쇼핑문의</div>

            {/** 문의 가능 시간 안내 */}
            <div className="text-[14px] mb-5">평일 10:00 ~ 18:00</div>

            {/**------------------------------- 문의하기 버튼 ------------------------------- */}
             <button
                onClick={()=>{setOpenInquiry(true)}}
                className="w-full mx-auto block py-[10px] font-semibold border-[#EB5176] text-[#EB5176] border-[1px] rounded-full">
                <span>판매자 상품 문의</span>
            </button>
            

        </div>


        {/** 쇼핑몰 안내 사항 */}
        <div className="bg-[#EFEFEF] p-4">
            <span className="text-[#9E9E9E] text-sm w-full">
            주식회사 제제미미는 통신판매중개자로서, 통신판매의 당사자가 아닙니다. 
            상품, 상품정보, 거래에 관한 의무와 책임은 판매자에게 있습니다.
            상품에 대한 문의는 판매자 상품 문의로 진행해주시고 그 밖에 마켓관련 문의는 마이페이지에 문의하기를 통해 해주시기 바랍니다.
            </span>
        </div>

        <ProductInqury
            openInquiry={openInquiry}
            setOpenInquiry={setOpenInquiry}
            productDetail={productDetail}
        />
    
    </fieldset>)
}

export default BottomContents