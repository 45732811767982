import CustomPopup from "@components/snippets/CustomPopup"
import FloatingNotice from "@components/snippets/FloatingNotice"
import { faChevronDown, faChevronUp } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ButtonBase } from "@mui/material"
import axios from "axios"
import { IProductDetailInfos } from "interface/DetailInterface"
import useShopUser from "libs/useShopUser"
import { ChangeEvent, Dispatch, SetStateAction, useEffect, useRef, useState } from "react"
import { useRecoilState } from "recoil"
import ShopbyUrl from "shopbyUrls"
import { headerConfig } from "src/headerConfig"
import { InquiryContent } from "states/shop/InquiryContent"
import { removeWordRegex } from "util/regularExpression"

interface IProductInquryProps {
    openInquiry : boolean,
    setOpenInquiry : Dispatch<SetStateAction<boolean>>,
    productDetail ?: IProductDetailInfos  | null
}

const ProductInqury = ({openInquiry, setOpenInquiry, productDetail} : IProductInquryProps) =>{

    const { userkey } = useShopUser();
    const [hiddenInfo, setHiddenInfo] = useState<boolean>(false)
    const [inquiryTypeName, setInquiryTypeName] = useState<string>('문의하고자 하는 유형을 선택해주세요.');
    const [inquiryContent, setInquiryContent] = useRecoilState(InquiryContent)
    const [openSelectBox, setOpenSelectBox] = useState<boolean>(false);

    const inquiryType = [
        {type : 'PRODUCT', typeName : '상품 문의'}, 
        {type : 'DELIVERY', typeName : '배송 문의'}, 
        {type : 'CANCEL', typeName : '취소 문의'},
        {type : 'RETURN', typeName : '반품 문의'}, 
        {type : 'EXCHANGE', typeName : '교환 문의'}, 
        {type : 'REFUND', typeName : '환불 문의'}, 
        {type : 'OTHER', typeName : '기타 문의'}
    ]
                     

    {/** -------------------------------------------- 문의유형 선택 -------------------------------------------- */}
    const selectInquiryType = (type:string, typeName:string) =>{
        setInquiryContent(prev=> {return {...prev, type : type}})
        setInquiryTypeName(typeName);
        setOpenSelectBox(false)
    }

    {/** -------------------------------------------- 문의내용 입력 -------------------------------------------- */}
    const handleInputContent = (e: ChangeEvent<HTMLTextAreaElement>) =>{
        const {name, value} = e.target
        setInquiryContent(prev=>{ return{ ...prev, [name] : value }})
    }

    {/** -------------------------------------------- 문의 완료 -------------------------------------------- */}
    const submitInquiry = async(productNo:number) =>{
        if(!inquiryContent.type){
            alert('문의 유형을 선택해주세요.')
            return;
        }

        if(inquiryContent.content.length < 10){
            alert('최소 10자이상 문의내용을 입력해주세요.')
            return;
        }        

        await axios.post(
            `${ShopbyUrl.display.registerProductInquiry}/${productNo}/inquiries`, 
            inquiryContent, { 
            headers : {
                ...headerConfig, 
                accessToken : userkey
        }})
        .then((res)=>{alert('상품문의가 등록되었습니다.'); setOpenInquiry(false);})
        .catch((e)=>console.log(e, "e"))
    }

    return (
            
            <div className={openInquiry ? "fixed top-0 h-screen bg-white w-full left-0 z-50" : "hidden"}>

                <FloatingNotice preOpen>
                    <div className="flex py-2">
                        <img src="/assets/inquiryList.png" className="w-[36%] h-auto"/>
                        <div className="my-auto ml-2 text-left text-[0.88em]">
                            <p>작성하신 문의내용은</p>
                            <b>마켓 좌측 상단아이콘 &gt; 상품문의내역</b>
                            <p>에서 확인하실 수 있습니다.</p>
                        </div>
                    </div>
                </FloatingNotice>

                <div className="bg-white w-full h-[48px] z-50 flex items-center justify-between relative">
                    <ButtonBase
                        className= { "absolute top-1/2 translate-y-[-50%] left-4" } 
                        onClick={()=>{setOpenInquiry(prev=>!prev)}}>
                        <img className='w-6 h-6'  src="/assets/back.png"/>
                    </ButtonBase>
                        
                    <div className='font-[500] absolute translate-x-[-50%] left-1/2'>상품문의</div>
                    
                    <ButtonBase className='absolute right-4' onClick={()=>submitInquiry(productDetail?.baseInfo.productNo as number)}>
                        완료
                    </ButtonBase>
                </div>

                {productDetail && (

                    <div className="pb-5 pt-12 px-4">

                        {/** -------------------------------------------- 문의할 상품 정보 -------------------------------------------- */}
                        <div className="flex border-b-[1px] pb-4">
                            <div>
                                <div className="border-[1px] rounded-lg">
                                    <img src={productDetail.baseInfo.imageUrls[0]} className="w-[120px] aspect-square rounded-lg"/>
                                </div>
                            </div>

                            <div className="ml-4 mt-2">
                                <div className="text-[#888888] font-14">{productDetail.brand?.nameKo}</div>
                                <div>{removeWordRegex(productDetail.baseInfo.productName)}</div>
                            </div>
                        </div>

                        {/** -------------------------------------------- 문의유형 선택 -------------------------------------------- */}
                        <div className="py-4">
                            <div className="font-14 text-[#EB5176]">문의 유형 *</div>

                            <div className="border-b-[1px] relative w-full py-[6px] h-[40px] cursor-pointer text-[#888888]" onClick={()=>{setOpenSelectBox(prev=>!prev)}}>
                                    <span className="absolute">{inquiryTypeName}</span>
                                    <FontAwesomeIcon icon={openSelectBox ? faChevronUp : faChevronDown} className="absolute top-1/2 right-4 w-3 h-4 translate-y-[-50%]"/>
                            </div>

                            <div className={ openSelectBox ? `absolute bg-[#FFFFFF] w-full mt-1 z-20 rounded-lg border-[1px]` : `hidden`}>
                                {inquiryType.map((v,i)=>{
                                    return (
                                        <div className="border-b-[1px] p-2" onClick={()=>{selectInquiryType(v.type, v.typeName)}} key={v.typeName + i}>
                                            {v.typeName}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>

                        {/** -------------------------------------------- 문의내용-------------------------------------------- */}
                        <div>
                            <div className="relative">
                                <span className="text-[#EB5176] font-14">문의 내용 *</span>
                                <span className="text-[#888888] text-[12px] absolute top-1/2 translate-y-[-50%] right-0">{inquiryContent.content.length}자 / 최소 10자</span>
                            </div>

                            <div className="mt-1">
                                <textarea 
                                    rows={10}
                                    onFocus={()=>setHiddenInfo(true)}
                                    onBlur={()=>setHiddenInfo(false)}
                                    name="content"
                                    minLength={10}
                                    onChange={handleInputContent}
                                    placeholder="어떤 사항이 궁금하신가요?"
                                    className="resize-none p-2 w-full">
                                </textarea>
                            </div>
                        </div>
                    </div>
                )}

                {/** -------------------------------------------- 문의내용-------------------------------------------- */}
                <div className={`${hiddenInfo ? 'hidden' : 'fixed bottom-0 w-full h-[120px] bg-[#F4F4F4] p-4'}`}>
                    <span className="text-[#575C62] font-14">
                        *상품 문의 답변은 마켓홈 좌측 상단 [my아이콘] &#62; [상품 문의 내역]에서 확인 가능합니다.
                    </span>
                </div>

            </div>

    )
}

export default ProductInqury