export const exceptProduct = [
    110400943,
    114140617,
    114140613,
    114140583,
    110401023,
    107973523,
    109517503,
    110225659,
    110434979,
    110434565,
    110434963,
    110434961,
    110473355,
    110473699,
    110473741,
    110473775,
    110473801,
    110474341,
    110475475,
    110476019,
    110205899,
    110206875,
    110205837,
    110206873,
    110476979,
    110356141,
    110356393,
    110356695,
    110357853,
    110357907,
    110357967,
    110358713,
    110360429,
    110356211,
    110359203,
    110359055,
    110358835,
    108106149,
    108120553,
    108120555,
    108106155,
    108095593,
    108106145,
    108095521,
    108120221,
    108095417,
    108095473,
    108095515,
    108095523,
    108095547,
    108106137,
    108106139,
    108106195,
    108106141,
    108106153,
    108106159,
    108106161,
    108106171,
    108106177,
    108106179,
    108106183,
    108106185,
    108106187,
    108118243,
    108118279,
    108120461,
    108120551,
    108120561,
    108120563,
    108120567,
    108120569,
    108120573,
    108120575
]

// 어버이날 행사용 주문취소 못하게 하는 상품 - 임시 🌟
export const EXCEPT_PRODUCTS_PARENTSDAY = [110504091, 110504083, 110504065,110504039,110503103, 110504149]

// 무료인화
export const FREE_PRINT_PRODUCTS = [120703466, 120703365, 120862275, 120862278]
// 사진인화 그룹관리코드 
// 기본상품 + 추가 장당 가격상품의 경우 때문에 따로 분리가 필요함
export const PHOTO_GROUPCODE_PRODUCTS = [...FREE_PRINT_PRODUCTS, 110504039, 110504091,110504083,110504065,110504039,110503103, 108077555,107645883,106921349,106886629,106824323,106809857,106807551]

// 상품별로 그룹코드 필드명이 다름
export const getGroupcodeCntFieldName = (productNo: number) => {
    if (PHOTO_GROUPCODE_PRODUCTS.includes(productNo)) {
        return 'orderCnt'
    }
    return 'optionCnt' // 기본필드명
}