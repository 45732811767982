import { ButtonBase } from "@mui/material"
import axios from "axios"
import useShopUser from "libs/useShopUser"
import moment from "moment"
import React from "react"
import ShopbyUrl from "shopbyUrls"
import { headerConfig } from "src/headerConfig"
import { priceComma } from "util/priceComma"

interface couponItemProps {
    item : any,
    menuTab ?: string,
    extraFunc: any,
    extraFunc2: any,
}

const CouponItem = ({item, menuTab, extraFunc, extraFunc2} : couponItemProps) =>{
    const { userkey } = useShopUser();

    // 쿠폰 발급
    const handleDownloadCoupon = async(couponNo:number) =>{
        await axios.post(
            `${ShopbyUrl.promotion.downloadCoupon}/${couponNo}/download`, 
            { 'channelType' : null}, 
            { headers : { ...headerConfig, accessToken : userkey}})
        .then(
            async() =>{  
                alert("쿠폰발급이 완료되었습니다!");
                await extraFunc(); 
                await extraFunc2();
            }
        )
        
    }
    return (<React.Fragment key={item.couponNo}>

           <div className="relative">
                <div className="w-[328px] mb-4 aspect-[2/1] border-[1px] rounded-lg mx-auto flex justify-between" key={item.couponNo}>
                    {/* 쿠폰 상세내용 */}
                    <div className="w-[82%] h-full py-6 px-4 relative bg-[white] rounded-md">
                        
                        <div className="text-[#2C2C2C] text-[18px] font-[700] mb-5">{item.couponName}</div>

                        <div className="absolute bottom-5 font-[400] font-14 text-[#9E9E9E]">
                            <div>{item?.minSalePrice ? priceComma(item?.minSalePrice) + "원 이상 구매시 사용 가능" : item?.useConstraint ? priceComma(item?.useConstraint.minSalePrice) + "원 이상 구매시 사용 가능"  :  "" }</div>
                            <div>{moment(item.issueYmdt ?? item.dateInfo.issueStartYmdt).format('YYYY.MM.DD')} ~ {moment(item.useEndYmdt ?? item.useConstraint.useEndYmdt).format('YYYY.MM.DD')}</div>
                        </div> 
                    </div>

                    {/* 다운받기  버튼 */}
                    <ButtonBase 
                        disabled={menuTab !== "download"}
                        onClick={() =>handleDownloadCoupon(item.couponNo)}
                        className="w-[22%] h-full relative"
                        style={{backgroundColor : "#EB5176", borderRadius : '0 10px 10px 0'}}>

                        <img src={menuTab == "download" ? "/assets/download3.png" : "/assets/button_check_on3.png"} className="w-[40%] aspect-square absolute top-[44%] left-1/2 translate-x-[-50%] translate-y-[-50%]"/>
                        <span className="absolute top-[62%] left-1/2 translate-x-[-50%] translate-y-[-50%] font-14 text-[white] w-full">
                            {menuTab == "download" ? "다운로드" : "받기완료"}
                        </span>
                    </ButtonBase>
                </div>
            </div>
        </React.Fragment>
    )
}

export default CouponItem