import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IOptions, IProductDetailInfos } from "interface/DetailInterface"
import React, { useEffect, useRef, useState } from "react"
import { faChevronDown} from '@fortawesome/pro-regular-svg-icons'
import {faXmark } from "@fortawesome/pro-duotone-svg-icons";
import { priceComma } from "util/priceComma"
import { IReviewListResponse } from "interface/ReviewInterface";
import { isIOS } from "react-device-detect";

{/** 상품 디테일 정보 */}
const ProductDetailInfos = ({productDetail, optionInfo } : {productDetail ?: IProductDetailInfos | null, optionInfo ?: IOptions, reviews ?: IReviewListResponse | null} ) =>{

    const [choiceNo, setChoiceNo] = useState<string>('');
    const [optionName, setOptionName] = useState<string>('');
    const [optionDetails, setOptionDetails] = useState<string>();
    const [loadMore, setLoadMore] = useState<boolean>(false)
    const detailImgRef = useRef<HTMLDivElement>(null);
    const detailInfoRef = useRef<HTMLDivElement>(null);

    {/** 옵션 별 디테일 이미지 정보 가져오기  */}
    const openOptionDetail = async(optionNo:number, choiceNo:string, optionName:string)=>{
        setChoiceNo(choiceNo)
        setOptionName(optionName)
        const currentOption = (optionInfo as IOptions)?.flatOptions?.filter(item=>item.optionNo === optionNo)[0]
        if(currentOption?.images){
            const currentImage = currentOption.images[1].url
            setOptionDetails(currentImage)
        }
    }

    {/** 옵션 디테일 이미지 창닫기 */}
    const closeOptionDetail = () =>{
        setOptionDetails('');
        (detailInfoRef.current as HTMLDivElement).scrollIntoView({block : 'start', behavior: "smooth"})
    }

    useEffect(()=>{
        if(optionDetails){
            (detailImgRef.current as HTMLDivElement).scrollIntoView({block : 'start', behavior: "smooth"})
        }
    },[optionDetails])

    function transformHtml(inputHtml: string): string {
        const hrefRegex = /href="([^"]+)"/g;
        
        let match;
        let transformedHtml = inputHtml;
    
        while (match = hrefRegex.exec(inputHtml)) {
            const hrefValue = match[1];
            const newAnchorTag = `<a onClick="window.webkit.messageHandlers.openWebview.postMessage('${hrefValue}')"`;
            transformedHtml = transformedHtml.replace(match[0], newAnchorTag);
        }
    
        return transformedHtml;
    }

    return(<div>
            {/** 상세 이미지 팝업 */}
            <div ref={detailImgRef} className={optionDetails ? "absolute top-0 w-full min-h-screen h-[100%] z-50 left-0 bg-white" : "hidden"}>
                <div className="w-full h-[52px] bg-white relative border-b-[1px]">
                    <span className="absolute top-[50%] translate-y-[-50%] left-4 font-semibold">[ {choiceNo} ]</span>
                    <span className="absolute top-[50%] translate-y-[-50%] left-[78px]">{optionName}</span>
                    <FontAwesomeIcon onClick={closeOptionDetail} icon={faXmark} className="absolute top-[50%] translate-y-[-50%] right-5 w-4 h-5"/>
                </div>

                <div className="relative h-[120px] w-full bg-white">
                <div className="absolute top-5 left-[50%] translate-x-[-50%] text-[14px] bg-[#9C5800] text-[white] font-bold rounded-[3px] px-[6px] py-[3px]">{choiceNo}</div>
                    <span className="absolute top-[60px] left-1/2 translate-x-[-50%] text-[18px] font-semibold">{optionName}</span>
                </div>
                <img src={optionDetails}/>
            </div>
            <div className={`relative forced-column w-screen ${loadMore ? '' : 'max-h-[1200px] overflow-hidden'}`}>
                {productDetail 
                ? (<div> {/** --------------------------- 상단디테일 --------------------------- */}
                    {productDetail?.baseInfo.contentHeader !== '' && (
                        <div dangerouslySetInnerHTML={ {__html: productDetail?.baseInfo.contentHeader}}/>
                    )}

                    {/** --------------------------- 어드민에서 상세이미지 직접 등록했을 때의 화면 --------------------------- */}
                    {!!productDetail?.baseInfo.content && optionInfo
                    && (<div>
                        {optionInfo.flatOptions.find(v=> v.images?.length) && (
                        <div className="relative forced-column px-4 border-b-[1px]" ref={detailInfoRef}>
                            {/** --------------------------- 옵션에 등록된 이미지로 상세이미지 적용시켰을 때의 화면  --------------------------- */}
                            <div className="pt-6 pb-3 px-2 font-semibold">옵션선택</div> 

                            <div className="grid grid-cols-2 gap-2">
                                {optionInfo.flatOptions.filter(i => i.images?.length)?.map((item, idx)=>{
                                    return(<div className="h-[280px]" key={item.optionNo + idx}>        
                                    <div className="relative" onClick={()=>openOptionDetail(item.optionNo, `선택${idx+1}`, item.value)}>

                                        {/** 옵션 이미지 썸네일 영역 */}
                                        <img src={item.images && item.images[0]?.url} className="border-[1px] border-solid rounded-lg w-1/2 aspect-square"/>
                                        <div className="absolute -bottom-[12px] left-0 text-[14px] bg-[#2C2C2C] text-[white] font-bold rounded-[3px] px-[6px] py-[3px]">
                                            선택 {idx+1}
                                        </div>

                                        {/** 옵션 문구 */}  
                                        <div className="absolute -bottom-[80px]">
                                            <div className="text-[14px]">{item.value}</div>
                                            <div className="mt-[2px]">
                                                <span className="text-[#2C2C2C] font-[400] text-[14px]">{Math.ceil((productDetail.price.immediateDiscountAmt/productDetail.price.salePrice)*100)}%</span>
                                                <span className="text-[#D0422A] text-[18px] font-[500] ml-2">{priceComma(item.buyPrice)}원</span>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            )})}
                            </div>
                        </div>)}
                        {isIOS && productDetail ? <div dangerouslySetInnerHTML={ {__html: transformHtml(productDetail?.baseInfo.content) }}/> : <div dangerouslySetInnerHTML={ {__html: productDetail?.baseInfo.content}}/> }
                        

                        {/** --------------------------- 하단 디테일 --------------------------- */}
                        {!!productDetail.baseInfo.contentFooter && (
                            <div dangerouslySetInnerHTML={ {__html: productDetail.baseInfo.contentFooter}}/>
                        )}

                        {!loadMore && (<div className="w-full p-4 bg-white h-[80px] absolute bottom-0">
                            <div className="h-[52px] w-full border-[1px] border-[#2C2C2C] text-[#2C2C2C] text-center pt-[16px] text-[14px]" onClick={()=>{setLoadMore(true)}}>
                                <span>상품설명 더 보기 </span>
                                <FontAwesomeIcon className="ml-2 w-3 h-4" icon={faChevronDown} />
                            </div>
                        </div> )}
                    </div>)}
                </div>): (<></>)}
            </div>
        </div>)
}

export default ProductDetailInfos