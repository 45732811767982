import axios from "axios";
import copyTextToClipboard from "./copyTextToClipboard";

const copyClipboardAirbridge = async(
    url:string, 
    channel: string, // 채널 (어떤 경로로 들어왔는지 ex. 마켓공유 버튼으로 생성된 링크 )
    campaign: string, // 목적 - 현재 바라보고 있는 페이지 ex.상세페이지
    ad_group: string, // 광고그룹
    ad_creative: string, // 광고소재 - 마켓공유
    purpose:string, 
    ogImg?:string,
    content ?: string, // 기타정보1 - 우선순위 먼저
    term ?: string // 기타정보2 - 검색어 필드
) =>{
    
    const regExp = /[^가-힣a-zA-Z ]/g;  // 한글이랑 영문 아닌 글자 (이모지, 특수문자) 선택 => 추후 얘네만 소거

    const parsedURL = new URL(url);
    const { data } = await axios.post(
        `https://api.airbridge.io/v1/tracking-links`,
        {
            "isReengagement": "OFF",
            // 쑥찰 앱 없을 때 엔드포인트
            "fallbackPaths": {
                "android": "https://shop.jejememe.com/nopage?purpose=" + encodeURIComponent(purpose),
                "ios": "https://shop.jejememe.com/nopage?purpose=" + encodeURIComponent(purpose),
                "desktop": "https://shop.jejememe.com/nopage?purpose=" + encodeURIComponent(purpose)
            },
            "ogTag": {
                "title": term ?? "쑥쑥마켓 - 활동은 쑥쑥, 가격은 뚝뚝" , 
                "descripqtion": "지구에서 양육자를 가장행복하게, 쑥쑥찰칵에서 만드는 쑥쑥마켓입니다.",
                "imageUrl" : !!ogImg ? ogImg : "https://kr.object.iwinv.kr/static/%E1%84%8A%E1%85%AE%E1%86%A8%E1%84%8A%E1%85%AE%E1%86%A8%E1%84%86%E1%85%A1%E1%84%8F%E1%85%A6%E1%86%BA_%E1%84%80%E1%85%A9%E1%86%BC%E1%84%8B%E1%85%B2%E1%84%8A%E1%85%A5%E1%86%B7%E1%84%82%E1%85%A6%E1%84%8B%E1%85%B5%E1%86%AF.jpg"
            },
            "channel": channel,
            "campaignParams": {
                "campaign": campaign,
                "ad_group": ad_group,
                "ad_creative": ad_creative,
                "content" : content ? content.replaceAll(regExp, "") :  "",
                "term" : term ? term.replaceAll(regExp, "") :  ""
            },
            "deeplinkUrl": "daybabyday://ssmarket?url=" + `${encodeURIComponent(parsedURL.pathname)}${encodeURIComponent(parsedURL.search)}` +  "&target=window"   
        },{
            headers : {
                "accept": "application/json",
                "content-type": "application/json",
                "Authorization": "Bearer e95fc16032fc456ea369051abad90ce5"
            }
        }
    )

    copyTextToClipboard(data.data.trackingLink.shortUrl, "쑥쑥마켓 - 활동이 쑥쑥 가격은 뚝뚝", "쑥쑥마켓")
}

export default copyClipboardAirbridge