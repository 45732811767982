import { faCircleExclamation, faCircleX } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ButtonBase } from "@mui/material"
import { motion } from "framer-motion"
import React, { ReactNode, useEffect, useState } from "react"

interface INotice {
    position ?: string,
    children : ReactNode
    preOpen ?:boolean,
    className?: string
}

const FloatingNotice = ({
    position,
    children,
    preOpen,
    className = "",
} : INotice) => {
    const [openNotice, setOpenNotice] = useState<boolean | undefined>(false)

    useEffect(()=>{
        setOpenNotice(preOpen ?? false)
    },[preOpen])

    return (<div className={`${className} text-right ${position ?? "absolute top-14 right-4 z-10"}`}>
        <ButtonBase 
        onClick={()=>setOpenNotice(prev => !prev)}
        className="w-[7vw] sm:w-8 aspect-square rounded-full">
            {   
                openNotice
                ?<FontAwesomeIcon icon={faCircleX} className="h-full aspect-auto rounded-full bg-[#D9D9D9] text-white"/>
                :<FontAwesomeIcon icon={faCircleExclamation} className="h-full aspect-auto rounded-full bg-[#D9D9D9] text-white"/>}
        </ButtonBase>

        <motion.div
        initial={{ opacity : 0 }}
        animate={ openNotice ? "open" : "close"}
        variants={{ open : { opacity: 1, display : 'block' }, close : { opacity: 0, display : 'None' }}}
        transition={{ duration : 0.2 }}
        className={`top-right-tail z-20`}>
            {children}
        </motion.div>
    </div>)
}

export default FloatingNotice