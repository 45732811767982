import { IInquiry } from "interface/InquiryInterface";
import { atom } from "recoil";

export const InquiryContent = atom<IInquiry>({
    key : 'inquiryContent',
    default : {
        type : '', 
        secreted : false, 
        email : '', 
        content : '', 
        productNo : 0
    }
})